<template>
  <div class="user-inner-item" v-if="show">
    <div class="user-inner-item-nav">
      <h3>Token信息</h3>
    </div>
    <div class="user-inner-item-content">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="Token名称" prop="name">
          <el-input type="text" v-model.trim="name" placeholder="请根据应用场景命名，如：'智慧社区'"></el-input>
        </el-form-item>
        <el-form-item label="请求校验方式" required prop="validateType">
          <el-radio v-model="validateType" :label="0" size="large">站点访问</el-radio>
          <el-radio v-model="validateType" :label="1" size="large">服务请求</el-radio>
        </el-form-item>
        <el-form-item label="referer" v-if="validateType === 0" required prop="validateValue">
          <el-input type="text" v-model.trim="validateValue" placeholder="请输入单个http或https网址"></el-input>
        </el-form-item>
        <el-form-item label="访问源IP" v-else required prop="validateValue">
          <el-input
            type="text"
            v-model.trim="validateValue"
            placeholder="请输入IP地址，多个IP地址使用英文,分隔"
          ></el-input>
          <el-tooltip class="box-item" effect="dark" :content="toolString" placement="right">
            <i class="el-icon-info" style="margin-left: 10px; font-size: 14px"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="有效期至">
          <el-date-picker
            v-model="expireTime"
            type="datetime"
            placeholder="请选择日期和时间"
            clearable
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="关联服务：（有权限和已申请授权的服务）" required> </el-form-item>
        <el-form-item prop="serviceIds">
          <el-transfer
            v-model="chooseServices"
            style="text-align: left; display: inline-block"
            :titles="['所有服务', '关联服务']"
            filterable
            :format="{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}',
            }"
            :data="preChooseData"
            @change="handleTransferChange"
            :props="{
              key: 'id',
              label: 'serviceName',
            }"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item>
          <div class="submit-btns">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { addUpdateToken, getAuthSpaceList } from '@/api/cim';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: 'AddUpdateToken',
  data() {
    return {
      toolString: '如果设置0.0.0.0，允许所有ip访问（测试用）',
    };
  },
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      validateType: 0,
      validateValue: '',
      expireTime: '',
      serviceIds: [],
    });
    const preChooseData = ref([]);
    const formRef = ref(null);
    const rules = {
      name: [
        { required: true, message: '请输入Token名称', trigger: 'blur' },
        { min: 2, max: 20, message: '名称长度在2到20个字符', trigger: 'blur' },
      ],
      validateValue: [{ required: true, message: '请输入站点或服务信息', trigger: 'blur' }],
      serviceIds: [{ required: true, message: '请选择关联服务', trigger: 'blur' }],
    };
    const chooseServices = ref([]);
    const getPreChoose = async () => {
      const { data } = await getAuthSpaceList();
      preChooseData.value = data;
    };
    // getPreChoose();
    const handleTransferChange = (e) => {
      console.log(e);
      form.serviceIds = e.join(',');
    };
    const show = ref(false);
    const handleOpen = (value) => {
      getPreChoose();
      if (value.serviceIds) {
        form.name = value.name;
        form.value = value.value;
        form.serviceIds = value.serviceIds;
        form.validateValue = value.validateValue;
        form.validateType = value.validateType;
        form.expireTime = value.expireTime;
        chooseServices.value = value.serviceIds.split(',').map((x) => +x);
        if (value.id || value.id === 0) {
          form.id = value.id;
        }
      }
      show.value = true;
    };
    const handleClose = () => {
      form.expireTime = '';
      form.name = '';
      form.validateType = 0;
      form.validateValue = '';
      form.serviceIds = [];
      chooseServices.value = [];
      form.id = '';
      show.value = false;
    };
    const submit = async () => {
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
        } else {
          console.log('error submit!', fields);
        }
      });
      if (!form.name || !form.validateValue || !form.serviceIds || !form.serviceIds?.length) {
        return;
      }
      const { code } = await addUpdateToken(form);
      if (code === 0) {
        ElMessage.success(form.id ? '更新成功' : '新增成功');
        handleClose();
        emit('updateList');
      }
    };
    return {
      ...toRefs(form),
      preChooseData,
      handleTransferChange,
      chooseServices,
      show,
      handleOpen,
      handleClose,
      submit,
      form,
      rules,
      formRef,
    };
  },
});
</script>

<style lang="scss">
.user-inner-item {
  height: 100%;
  min-height: 500px;
  overflow-y: auto;
  width: 100%;
  background-color: white;
  position: absolute;
  z-index: 20;
  top: -10px;
  left: 0;
  padding-top: 10px;
  &-nav {
    padding-left: 20px;
    width: 100%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
  &-content {
    padding: 40px 20px 40px calc(50% - 280px);
    .el-form-item__label {
      min-width: 100px;
      text-align: left;
    }
    .el-form-item__content > .el-input--small {
      width: 460px;
    }
    .el-transfer-panel__body > .el-input--small {
      width: 88%;
    }
    .submit-btns {
      width: 340px;
      text-align: right;
      margin-top: 100px;
    }
  }
}
</style>
