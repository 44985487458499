import URL from '@/shared/constant/url';
import { getUrl, parseApiUrl } from '../utils';
import { apiProxy } from '../proxy/proxy';
import SERVER_TYPE from '@/shared/servertype';
import request from '@/utils/request';

// 获取目录树
export const getCategoryTree = (payload?: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_CATEGORY_TREE, {
    method: 'get',
    data: payload,
  });
// 新增目录
export const addCategory = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_CATEGORY_ADD, {
    method: 'post',
    data: payload,
  });
// 更新目录
export const updateCategory = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_CATEGORY_EDIT, {
    method: 'post',
    data: payload,
  });
// 删除目录
export const deleteCategory = (id: number, payload?: any) =>
  apiProxy(SERVER_TYPE.CIM, parseApiUrl(URL.cim.POST_CATEGORY_DELETE, { id }), {
    method: 'post',
    data: payload,
  });

// 获取空间服务列表
export const getSpaceServiceList = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_SPACE_SERVICE_LIST, {
    method: 'post',
    data: payload,
  });
// 获取缩略图列表
export const getSpaceListWithPic = (payload: any) => request.post(getUrl(URL.cim.GET_LIST_WITH_PICTURE), payload);

export const getSpaceServiceExcel = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_SPACE_EXCEL, {
    method: 'post',
    data: payload,
  });

// 获取空间服务详情
export const getSpaceServiceDetail = (id: number, userId?: any, payload?: any) => {
  const preUrl = userId ? `/${userId}` : '';
  return apiProxy(SERVER_TYPE.CIM, parseApiUrl(URL.cim.GET_SPACE_SERVICE_DETAIL + preUrl, { id }), {
    method: 'post',
    params: payload,
  });
};

// 删除空间服务
export const deleteSpaceService = (id: number, payload?: any) =>
  apiProxy(SERVER_TYPE.CIM, parseApiUrl(URL.cim.POST_SPACE_SERVICE_DELETE, { id }), {
    method: 'post',
    data: payload,
  });

// 批量删除空间服务
export const multipleDeleteService = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_MULTIPLE_SERVICE_DELETE, {
    method: 'post',
    data: payload,
  });

// 移动服务到某个目录下
export const moveSpaceService = (params: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_SPACE_SERVICE_MOVE, {
    method: 'get',
    params,
  });

// 注册空间服务
export const registerSpaceService = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_SPACE_SERVICE_REGISTER, {
    method: 'post',
    data: payload,
  });

// 更新空间服务
export const updateSpaceDetail = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_UPDATE_SPACE_DETAIL, {
    method: 'post',
    data: payload,
  });

// 新增/编辑标签
export const saveTag = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_TAG_OPERATE, {
    method: 'post',
    data: payload,
  });

// 获取带统计的标签数据
export const getTagsWithCount = (payload?: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_TAG_WITH_COUNT, {
    method: 'get',
    params: payload,
  });

// 获取空间服务类型
export const getSpaceServiceTypes = (payload?: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_SPACE_SERVICE_TYPES, {
    method: 'get',
    params: payload,
  });

export const validateUrl = (params: { url: string }) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.VALIDATE_URL, {
    method: 'get',
    params,
  });

// 移动目录

export const moveCatalogItem = (data: { catalogMoveInfo: { id: number; sort: number }[] }) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_MOVE_CATALOG_ITEM, {
    method: 'post',
    data,
  });

// 获取成都下属行政区划
export const getAreaCodes = (parentId = 1) =>
  apiProxy(SERVER_TYPE.CIM, parseApiUrl(URL.cim.GET_AREA_CODE, { parentId }), {
    method: 'post',
  });

// token换session
export const getSession = (token: string) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_TOKEN, {
    method: 'get',
    params: {
      token,
    },
  });

export const getToken2Session = (token: string, tenantId = 8) =>
  request.get(getUrl(URL.cim.GET_SESSION_BY_TOKEN), {
    params: {
      token,
      tenantId,
    },
  });

export const getAreaName = (userId: string | number) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_AREA_NAME, {
    method: 'get',
    params: {
      id: userId,
    },
  });

// pageNo, pageSize, keyword
export const getMyTokens = (params: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_MY_TOKENS, {
    method: 'get',
    params,
  });

export const addUpdateToken = (payload: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_ADD_UPDATE_TOKEN, {
    method: 'post',
    data: payload,
  });

export const deleteToken = (ids: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_DELETE_TOKEN, {
    method: 'post',
    data: {
      ids,
    },
  });

export const getAuthSpaceList = () =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_ALL_SPACE_LIST, {
    method: 'get',
  });

export const getIdentifyButton = (serviceId: string) =>
  apiProxy(SERVER_TYPE.CIM, `${URL.cim.GET_IDENTIFY_BUTTON}/${serviceId}`, {
    method: 'get',
  });

export const postApply = (data: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_APPLY, {
    method: 'post',
    data,
  });

export const postUpdateApplyDetail = (data: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_UPDATE_APPLY_DETAIL, {
    method: 'post',
    data,
  });
export const postApplyList = (data: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_APPLY_LIST, {
    method: 'post',
    data,
  });
export const postUpdateApplyStatus = (data: any) =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.POST_UPDATE_APPLY_STATUS, {
    method: 'post',
    data,
  });
export const getApplyStatusCount = () =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_APPLY_STATUS_COUNT, {
    method: 'get',
  });

export const getRootAreaCode = () =>
  apiProxy(SERVER_TYPE.CIM, URL.cim.GET_ROOT_AREA_CODE, {
    method: 'get',
  });

export const getNextAreaCode = (code: string) =>
  apiProxy(SERVER_TYPE.CIM, `${URL.cim.GET_NEXT_LEVEL_AREA_CODE}/${code || 1029394888}`, {
    method: 'get',
  });
