<template>
  <div class="user-center-item" style="padding: 20px 20px">
    <div class="token-table">
      <div class="token-table__header">
        <div class="token-table__header_button">
          <el-button type="primary" style="margin-right: 10px" @click="handleOpenToken">添加Token </el-button>
          <el-button @click="handleMultipleDelete" :disabled="!multipleSelection.length">批量删除</el-button>
        </div>
        <el-input placeholder="请输入名称" @input="filterSpaceService" v-model="searchProps.keyword"></el-input>
      </div>
      <div class="token-table__content">
        <list-wrap
          :loading="tableState.loading"
          :empty="tableState.tableData.length === 0"
          :handleCreate="handleCreate"
          :useSelectAuth="false"
        >
          <el-table
            :data="tableState.tableData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @sort-change="handleSortChange"
          >
            <el-table-column type="selection" :selectable="(row) => !row.type"></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="Token(密钥)" prop="value">
              <template #default="scope">
                <span
                  >{{ scope.row.value }} <i class="el-icon-copy-document" @click="copyUrl(scope.row.value)"></i
                ></span>
              </template>
            </el-table-column>
            <el-table-column label="请求校验方式" prop="validateType">
              <template #default="scope">
                <span>{{ scope.row.validateType ? '服务请求' : '站点访问' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="关联服务数量" prop="relCount">
              <template #default="scope">
                <span>{{ scope.row.relCount || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效期至" prop="expireTime" sortable="custom">
              <template #default="scope">
                <span>{{ scope.row.expireTime || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="text" @click="handleOpenToken(scope.row)" :disabled="scope.row.type === 1"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <packaged-pagination
            v-if="tableState.tableData.length && !tableState.loading"
            :current-page="searchProps.pageNo"
            :page-size="searchProps.pageSize"
            :total="tableState.total"
            @size-change="handlePageSizeChange"
            @current-change="handlePageChange"
          ></packaged-pagination>
        </list-wrap>
      </div>
    </div>
    <input id="hiddenInput" type="text" style="position: absolute; z-index: -10; opacity: 0" />
    <add-update-token ref="addTokenRef" @update-list="getTokensData"></add-update-token>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { deleteToken, getMyTokens } from '@/api/cim';
import { ElMessage } from 'element-plus';
import AddUpdateToken from '@/views/service-resource-center/apply-management/AddUpdateToken';

export default defineComponent({
  name: 'MyToken',
  components: {
    AddUpdateToken,
  },
  setup() {
    const tableState = reactive({
      tableData: [],
      total: 0,
      loading: false,
    });
    const searchProps = reactive({
      pageNo: 1,
      pageSize: 10,
      keyword: '',
      sortType: '',
    });
    const getTokensData = async () => {
      tableState.loading = true;
      try {
        const { data } = await getMyTokens(searchProps);
        data.list.forEach((x, i) => {
          if (x.type === 1) {
            data.list.splice(i, 1);
            data.list.unshift(x);
          }
        });
        tableState.tableData = data.list;
        tableState.total = data.total;
        tableState.loading = false;
      } catch (e) {
        console.log(e);
        tableState.loading = false;
      }
    };
    getTokensData();
    const multipleSelection = ref([]);
    const handleSelectionChange = (val) => {
      multipleSelection.value = val;
    };
    const handleMultipleDelete = async () => {
      const ids = multipleSelection.value.map((item) => item.value.id || item.id).join(',');
      const { code } = await deleteToken(ids);
      if (code === 0) {
        ElMessage.success('删除成功');
        await getTokensData();
      }
    };
    const filterSpaceService = () => {
      searchProps.pageNo = 1;
      getTokensData();
    };
    const handleCreate = () => {
      console.log('handleCreate');
    };
    const handleDetail = () => {
      console.log('handleDetail');
    };
    const handlePageSizeChange = (e) => {
      searchProps.pageSize = e;
      searchProps.pageNo = 1;
      getTokensData();
    };
    const handlePageChange = (e) => {
      searchProps.pageNo = e;
      getTokensData();
    };
    const copyUrl = (tokenString) => {
      const input = document.getElementById('hiddenInput');
      input.value = tokenString;
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        ElMessage.success('复制至剪切板');
      }
    };
    const addTokenRef = ref(null);
    const handleOpenToken = (value) => {
      console.log(value);
      addTokenRef.value.handleOpen(value);
    };
    const handleSortChange = (e) => {
      searchProps.sortType = e.order?.slice(0, 3) || '';
      searchProps.pageNo = 1;
      getTokensData();
    };
    return {
      multipleSelection,
      searchProps,
      handleMultipleDelete,
      filterSpaceService,
      tableState,
      handleCreate,
      handleSelectionChange,
      handleDetail,
      handlePageSizeChange,
      handlePageChange,
      copyUrl,
      handleOpenToken,
      addTokenRef,
      getTokensData,
      handleSortChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-center-item {
  height: 100%;
  width: 100%;
  min-height: 500px;
  overflow-y: hidden;
  padding-right: 20px;
  position: relative;
  .token-table {
    height: 100%;
    overflow-y: auto;
    .token-table__header {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
      :deep(.el-input.el-input--small) {
        width: 300px;
      }
    }
    :deep(.token-table__content) {
      .status-content {
        display: inline-flex;
        align-items: center;
        .status-content__border {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: transparent;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
    :deep(.el-pagination) {
      width: auto;
      margin-top: 0;
    }
    :deep(.el-table__row) {
      &:nth-child(1) {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
